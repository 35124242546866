import { generateMessage } from './function'

export const colorQuantity = (type, value) => {
  let def_color = "gray";
  if(type === "null" || value === "null") return def_color;
  if(type === "reserved") {
    if(value < 10) return "red";
    else if(value >= 10) return "green";
  }else if (type === "available") {
    if(value === 0) return "gray";
    else if(value < 10) return "red";
    else if(value >= 10) return "green";
  }else return def_color;
}

export const colorOrderStatus = (value) => {
  if(!value) return '';
  const status = value.toLowerCase(),
    statusObj = { 'cancelled': 'darkgray', 'not paid': 'red', 'pending payment' : 'red' }
  return statusObj[status] || (String(status.match(/^open.*/)) || String(status.match(/^printed.*/))) ? 'orange' : 'green';
}

export const dateRangeStatus = (value) => {
  const statusObj = { 'today': 'Today', 'lastweek': 'Last 7 days', 'lastsecondweek': 'Last 14 days', 'lastmonth': 'Last 30 days', 'lastquart': 'Last 90 days', 'custom': 'Date' };
  return statusObj[value];
}

export const dateRangeDays = (value) => {
  const dayObj = { 'today': 0, 'lastweek': 6, 'lastsecondweek': 13 ,'lastmonth': 29, 'lastquart': 89, 'custom': 0 };
  return dayObj[value];
}

export const colorReturnStatus = (value) => {
  if(!value) return '';
  const statusObj = { 'complete': 'green', 'partial received': 'orange', 'open' : 'orange' };
  return statusObj[value.toLowerCase()] || 'green';
}

export const errorRegisterStatus = (valueArray, name) => {
  let newValue = null
  if(Array.isArray(name) && Object.keys(valueArray).length) {
    name.forEach(element => {
      if(valueArray[element]) newValue = valueArray[element] 
      else if(newValue) newValue = newValue[element]
      return newValue
    } )
  }else newValue = valueArray[name]
  return newValue ? true : false;
} 

export const colorAWB = (value) => {
  if(!value) return '#b2b2b2'
  switch(value){
    case 'Not_Printed':
    case 'AWB_Not_Printed':
      return '#b2b2b2'
    case 'Printed':
    case 'Shipping_Label_Printed':
    case 'AWB_Printed':
      return '#21ba45'
    case 'Ready_to_Print':
    case 'Waiting_AWB':
    case 'Shipping_Label_to_Print':
      return '#F8813E'
    case 'RTS_is_Required':
    case 'Required_Ready_to_Ship':
      return '#ff585d'
    default:
      return '#B2B2B2'
  }
}

export const colorPick = (value) => {
  if(!value) return '#696969'
  switch(value){
    case 'Not Picked':
      return '#696969'
    case 'Picklist Created':
    case 'Picklist in Progress':
      return '#F8813E'
    case 'Picked':
      return '#21ba45'
    default:
      return '#696969'
  }
}

export const colorPack = (value) => {
  if(!value) return '#696969'
  switch(value){
    case 'Not Packed':
      return '#696969'
    case 'Packed':
      return '#21ba45'
    default:
      return '#696969'
  }
}

export const colorShipment = (value) => {
  if(!value) return '#B2B2B2'
  switch(value){
    case 'Not_Shipped':
      return '#B2B2B2'
    case 'Shipped':
    case 'Ready_to_Ship':
    case 'Delivered':
      return '#21BA45' 
    default:
      return '#B2B2B2'
  }
}

export const colorInvoice = (value) => {
  if(!value) return 'darkgray'
  switch(value){
    case 'Open' :
      return '#F8813E'
    case 'Paid':
      return '#21BA45'
    case 'Unpaid':
    case 'Overdue':
    case 'Written_off':
    case 'Write Off':
    case 'Written Off':
      return '#FF585C'
    case 'Partially_paid':
      return 'orange'
    case 'Voided':
    case 'Cancelled':
      return '#A9A9A9'
    default:
      return 'green'
  }
}

export const colorStatus = (value) => {
  if(!value) return '#F8813E'
  switch(value){
    case 'Pending_payment':
    case 'Pending_courier':
    case 'pending payment':
    case 'Pending Payment':
    case 'pending courier':
    case 'not started yet':
    case 'Pending':
    case 'Draft':
      return '#FF585C'
    case 'Open':
    case 'open':
    case 'In_picking':
    case 'Partial_picked':
    case 'in picking':
    case 'In Picking':
    case 'Incoming':
      return '#F8813E'
    case 'Picked':
    case 'picked':
    case 'Packed':
    case 'Ready_to_ship':
    case 'ready to ship':
    case 'Ready to Ship':
    case 'Ready_to_pickup':
    case 'Shipped': 
    case 'shipped': 
    case 'Fulfilled': 
    case 'Delivered':
    case 'delivered':
    case 'Picked_up':
    case 'Invoiced':
    case 'Not_invoiced':
    case 'Invoice_paid': 
    case 'Completed': 
    case 'completed': 
    case 'Paid':
    case 'In Progress':
    case 'Active':
    case 'Confirmed':
      return '#21BA45'
    case 'Not_picked':
    case 'Not Picked':
    case 'Unpaid':
    case 'Overdue':
    case 'Write_off':
    case 'Write Off':
    case 'write off':
    case 'Written Off':
    case 'Failed Delivery':
    case 'failed delivery':
      return '#FF585C'
    case 'not shipped':
    case 'Not Shipped':
    case 'Partial_paid':
      return '#F8813E'
    case 'Cancelled': 
    case 'cancelled': 
    case 'Voided': 
      return '#a9a9a9'
    case 'Returned': 
      return '#ff585d'
    case 'Closed':
      return '#2c913d'
    default:
      return 'green'
  }
}

export const colorListingStatus = (value) => {
  if(!value) return '#696969'
  switch(value){
    case 'live':
      return '#21BA45'
    case 'inactive':
      return '#FF585C'
    case 'qc pending':
    case 'failed':
      return '#F8813E'
    case 'in progress':
      return '#429ddf'
    default:
      return '#696969'
  }
}

export const colorPromotionStatus = (value) => {
  if(!value) return '#696969'
  switch(value){
    case 'Active': 
    case 'active':
    case 'Ongoing': 
    case 'ongoing':
      return '#21BA45'
    case 'Upcoming':
    case 'upcoming':
      return '#F8813E'
    case 'Completed':
    case 'completed':
      return '#A9A9A9'
    default:
      return '#696969'
  }
}

export const channelConsignment = (name) => {
  if(!name) return ''
  switch(name) {
    case 'Tokopedia':
      return 'TokoCabang'
    case 'Lazada':
      return 'FBL'
    case 'JD Indonesia':
      return 'FBJ'
    case 'Shopee':
      return 'SBS'
    default:
      return ''
  }
}

export const filterSalesOrder = () => {
  const value = {
    'all': null,
    'pending': {
      'all': ['pending payment'],
      'pending-payment': ['pending payment'],
      'pending-courier': ['pending courier']
    },
    'open': {
      'all': ['open'],
    },
    'inprocess': {
      'all': ['not shipped','ready to ship'],
      'not_shipped': ['not shipped'],
      'ready_to_ship': ['ready to ship']
    },
    'fulfilled': {
      'all': ['shipped','delivered'],
      'shipped': ['shipped'],
      'delivered': ['delivered']
    },
    'completed': {
      'all': ['completed']
    },
    'failed': {
      'all': ['failed delivery']
    },
    'cancelled': {
      'all': ['cancelled']
    }
  }
  return value
}

export const filterShipment = () => {
  const value = {
    'all': [],
    'notshipped': ['not shipped'],
    'readytoship': ['ready to ship'],
    'shipped': ['shipped'],
    'delivered': ['delivered'],
    'failed': ['failed delivery'],
    'cancelled': ['cancelled']
  }
  return value
}

export const filterInvoice = () => {
  const value = {
    'all': null,
    'unpaid': 'Unpaid',
    'overdue': 'Overdue',
    'partialpaid': 'Partial Paid',
    'paid': 'Paid',
    'voided': 'Voided',
    'writtenoff': 'Written Off'
  }
  return value
}

export const filterActivityLog = () => {
  const value = {
    'all': [''],
    'product': ['create master product', 'update master product', 'active inactive', 'add new listing', 'import listing', 'price adjustment', 'confirm replace', 'create stock allocation', 'update image', 'remove listing'],
    'stock': ['stock adjustment', 'priority stock'],
    'order': ['mark as paid', 'create shipment', 'create picklist', 'create package', 'ready to ship', 'mark as delivered', 'cancel order', 'create sales return', 'confirm received returned'],
    'download': ['sales order', 'template stock adjustment', 'template cross listing', 'listing status', 'download item pending action', 'download template price', 'download template master item', 'download invoice', 'download payout summary', 'download payment received'],
    'print': ['print shipping document', 'print picklist', 'print packlist', 'print invoices'],
    'authentication': ['update credential', 'reconnect account']
  }
  return value
}

export const printResultMessage = (type, _message, result, totalData, callback) => {
  let message = ''
  if(type === 'failed') message = generateMessage('failed', _message ? _message : `All orders failed Printed. Please check again`)
  else if(type === 'progress'){
    if(result) {
      if(result.failedOrders && result.failedOrders.length > 0) {
        message = generateMessage('failed', `${result.failedOrders.length} orders Failed to be printed ${(totalData !== '' ? `(from ${totalData} orders)`: '')}`)
      }
      else if(result.successSummary){
        message = generateMessage('success', result.successSummary, callback)
      }else message = generateMessage('failed', _message ? _message : `All orders failed Printed. Please check again`)
    }else message = generateMessage('success', _message ? _message : `All orders successfully Printed.`, callback)
  }else message = generateMessage('success', _message ? _message : `All orders successfully Printed.`, callback) 
  return message
}

export const errorTitle = (name) => {
  switch(name){
    case 'Quantity on Hand' :
      return 'Quantity'
    case 'Variant Option 1' :
      return 'Variant Option'
    case 'Variant Type 1' :
      return 'Variant Type'
    case 'Variant Option 2' :
      return 'Variant Option'
    case 'Variant Type 2' :
      return 'Variant Type'
    case 'variants-sku':
      return 'Master SKU'
    default:
      return name
  }
}

export const errorMessage = (value) => {
  switch(value){
    case 'unique' :
      return 'already exists'
    default:
      return 'is required'
  }
}

export const colorAvailableQuantity = (value) => {
  if(value <= 0) return "red"
  else return "lightgreen"
}

export const colorChat = (value) => {
  if(!value) return '#429ddf'
  switch(value){
    case 'mine':
      return '#429ddf'
    case 'unserved':
      return '#fc5c64'
    case 'served':
      return 'green'
    case 'resolved':
      return '#a9a9a9'
    default:
      return '#429ddf'
  }
}

export const channelMap = (value) => {
  const channel = {
    '2': 'Shopify',
    '3': 'Lazada',
    '5': 'Blanja',
    '6': 'Matahari mall',
    '7': 'Magento 1',
    '8': 'Elevania',
    '9': 'Blibli', 
    '1o': 'Magento 2',
    '11': 'Bukalapak',
    '12': 'Shopee',
    '13': 'Zalora Indonesia',
    '15': 'Tokopedia',
    '16': 'JD Indonesia',
    '18': 'Zilingo',
    '19': 'Woocommerce',
    '21': 'Manual SO',
    '3532': 'Aladin Mall',
    '10017': 'Grabmart',
    '10030': 'Tiktok',
  }
  return channel[value.toString()] || ''
}

export const optionCountVariant = (variants) => {
  let result = 0
  if(variants) {
    result = variants.reduce((prev, curr) => {
      const count= curr.options ? curr.options.length : 0
      return prev < count ? count : prev
    }, 0)
  }
  return result
}

export const pageMasterTable = (value) => {
  if(!value) return ''
  switch(value){
    case 'create-master': case 'edit-master':
      return 'master'
    case 'create-listing': case 'edit-listing':
      return 'listing'
    case 'confirm-adjustment': case 'confirm-inbound': case 'confirm-outbound': case 'create-adjustment': case 'create-inbound': case 'create-outbound': case 'create-transfer': case 'create-price':
      return 'stock'
    case 'create-promotion': 
      return 'promotion'
    case 'create-paymentreceive':
      return 'payment'
    case 'edit-picklist': case 'create-picklist':
      return 'picklist'  
    default:
      return ''
  }
}

export const colorListing = (value) => {
  if(!value) return 'initial'
  switch(value){
    case 'SKU Matched':
    case 'SKU Not Matched':
    case 'Empty SKU':
      return 'orange'
    case 'SKU Duplicated':
      return 'red'
    default:
      return 'initial'
  }
}

export const filterPromotion = () => {
  const value = {
    'all': null,
    'ongoing': 'ongoing',
    'upcoming': 'upcoming',
    'completed': 'completed'
  }
  return value
}

export const picklistUpdateType = (value) => {
  if(!value) return 'Update Picklist'
  switch(value){
    case 'start-picking':
      return 'Start Picking'
    case 'continue-picklist':
      return 'Continue Picklist'
    default:
      return 'Update Picklist'
  }
}

export const historyType = (name) => {
  if(!name) return ''
  switch(name) {
    case 'Transfer':
      return 'ST'
    case 'Adjustment':
    case 'Adjustment (API)':
    case 'Adjustment (Interface & Excel)':
      return 'SA'
    case 'Return':
      return 'RMA'
    case 'Sales Order':
      return 'SO'
    case 'Opening Balance':
      return ''
    default:
      return ''
  }
}

export const colorCommonQuantity = (value) => {
  if(value <= 0) return "red"
  else return "green"
}

export const isSalePriceChannel = (id) => {
  // Blibli, Lazada, Woocommerce, Bukalapak, Zalora, Shopify, Flik
  const channelIds = [9, 3, 19, 11, 13, 2, 10055]
  return id ? channelIds.includes(parseInt(id)) : false
}

export const filterPriceAdjustment = () => {
  const value = {
    'all': null,
    'pending': 'pending',
    'confirmed': 'confirmed'
  }
  return value
}